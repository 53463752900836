<template>
  <div>
    <b-card title="검색 필터">
      <b-row class="mb-1">
        <b-col cols="4">
          <b-form-group
            label="시작 시간"
            label-cols="2"
          >
            <flat-pickr
              v-model="startAt"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                maxDate: 'today',
                minDate: '2019-04'
              }"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="끝 시점"
            label-cols="2"
          >
            <flat-pickr
              v-model="endAt"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                maxDate: 'today'
              }"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="1" v-text="'메시지 타입'" />
        <b-col>
          <b-form-radio-group
            v-model="selectedMsgType"
            :options="typeOptions"
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="1" v-text="'성공 여부'" />
        <b-col>
          <b-form-radio-group
            v-model="result"
            :options="resultOptions"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          align-self="center"
          cols="1"
          v-text="'전화번호'"
        />
        <b-col
          cols="3"
          class="pl-0"
        >
          <b-input-group>
            <b-form-input
              v-model="phoneNumber"
              placeholder="전화번호 전체(-빼고)"
            />
          </b-input-group>
        </b-col>
        <b-col
          cols="4"
        >
          <b-button
            variant="primary"
            class="float-right"
            @click="searchSentSMSList"
            v-text="'검색'"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-table
      :items="searchResult"
      :fields="tableColumnNames"
      show-empty
      empty-text="검색 결과가 없습니다."
      responsive
      small
    >
      <template #cell(msg_type)="data">
        <div style="width: 60px; text-align: center;">
          {{ data.value }}
        </div>
      </template>
      <template #cell(send_time)="data">
        <div style="width: 100px; text-align: center;">
          {{ data.value }}
        </div>
      </template>
      <template #cell(result)="data">
        <div style="width: 50px;">
          {{ data.value }}
        </div>
      </template>
      <template #cell(failReason)="data">
        <div style="width: 120px; text-align: center; white-space:no-wrap">
          {{ failReason(data.item.msg_type, data.item.result) }}
        </div>
      </template>
      <template #cell(text)="data">
        <div class="table-content__text">
          {{ data.value }}
        </div>
      </template>
      <template #cell(show-full-text)="data">
        <a
          v-b-modal.show-full-text-modal
          style="color:#258BFF; cursor:pointer;"
          @click="showFullText(data.item)"
          v-text="'내용 전체보기'"
        />
      </template>
    </b-table>
    <b-pagination
      v-if="resultTotalCount > 0"
      v-model="currentPage"
      :total-rows="resultTotalCount"
      :per-page="size"
    />
    <b-modal
      id="show-full-text-modal"
    >
      {{ fullText }}
    </b-modal>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import {
  BTable, BCard, BRow, BCol, BFormGroup,
  BFormRadioGroup, BInputGroup, BFormInput,
  BButton, BFormSelect, BPagination, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import router from '@/router'

export default defineComponent({
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BPagination,
    flatPickr,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const startAt = ref('')
    const endAt = ref('')
    const currentPage = ref(1)
    const result = ref('')
    const selectedMsgType = ref('')
    const phoneNumber = ref(router.history.current.query.phone_number)
    if (router.history.current.query.start_at) {
      startAt.value = moment(router.history.current.query.start_at).format('YYYY-MM-DD HH:mm')
    } else {
      startAt.value = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm')
    }
    if (router.history.current.query.end_at) {
      endAt.value = moment(router.history.current.query.end_at).format('YYYY-MM-DD HH:mm')
    } else {
      endAt.value = moment().format('YYYY-MM-DD HH:mm')
    }
    if (router.history.current.query.page) {
      currentPage.value = router.history.current.query.page
    }
    if (router.history.current.query.msg_type) {
      selectedMsgType.value = router.history.current.query.msg_type
    }
    if (router.history.current.query.result) {
      result.value = router.history.current.query.result
    }
    const size = 20
    const typeOptions = ref([
      { text: '전체', value: '' },
      { text: 'MMS', value: '3' },
      { text: '카카오톡', value: '6' },
    ])
    const resultOptions = ref([
      { text: '전체', value: '' },
      { text: '성공', value: '1' },
      { text: '실패', value: '0' },
    ])
    const fullText = ref('')
    const failReason = (msgType, resultCode) => {
      if (resultCode === '100') {
        return ''
      }
      let resultString;
      if (msgType === '6') {
        switch (resultCode) {
          case '100':
            resultString = ''
            break
          case '3008':
            resultString = '전화번호 오류'
            break
          case '3015':
            resultString = '탬플릿 못 찾음'
            break
          case '3016':
            resultString = '내용와 탬플릿 불일치'
            break
          case '3018':
            resultString = '메시지 전송 불가'
            break
          case '7109':
            resultString = '수신여부 불투명'
            break
          default:
            resultString = '알수없음'
            break
        }
      } else {
        switch (resultCode) {
          case '100':
            resultString = ''
            break
          case '201':
            resultString = '미등록번호'
            break
          case '302':
            resultString = '단말기 전원 문제'
            break
          case '306':
            resultString = '기타 에러'
            break
          default:
            resultString = '알수없음'
            break
        }
      }
      return resultString
    }
    
    const msgTypeToString = msgType => {
      let msgTypeString;
      switch (msgType) {
        case '3':
          msgTypeString = 'MMS'
          break;
        case '6':
          msgTypeString = '카카오톡'
          break;
        default:
          msgTypeString = '알수없음'
          break;
      }
      return msgTypeString
    }

    const searchResult = ref([])
    const resultTotalCount = ref(0)
    const tableColumnNames = [
      { key: 'mseq', label: '번호' },
      { key: 'msg_type', label: '메시지 타입', formatter: val => msgTypeToString(val) },
      { key: 'dstaddr', label: '번호' },
      { key: 'send_time', label: '전송일시', formatter: val => `${moment(val).utc().format('YYYY-MM-DD HH:mm')}` },
      { key: 'result', label: '결과', formatter: val => ( val === '100' ? '성공' : '실패' )},
      { key: 'failReason', label: '실패 사유' },
      { key: 'text', label: '내용' },
      { key: 'show-full-text', label: '내용 전체보기' },
    ]
    const showFullText = item => {
      fullText.value = item.text
    }

    const searchSentSMSList = () => {
      const start_at = moment(startAt.value).format('YYYY-MM-DD HH:mm')
      const end_at = moment(endAt.value).format('YYYY-MM-DD HH:mm')
      if (start_at > end_at) {
        alert('시작일자가 끝 일자보다 클 수는 없습니다')
        return;
      }
      const queryParams = {
        start_at,
        end_at,
        msg_type: selectedMsgType.value,
        result: result.value,
        phone_number: phoneNumber.value,
        page: currentPage.value,
        size,
      }
      axios.get(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/messages/`,
        { params: queryParams },
      ).then(res => {
        searchResult.value = res.data.data.logs
        resultTotalCount.value = res.data.data.total_count
      }).catch(error => {
        console.log(error.response.data)
        searchResult.value = []
        resultTotalCount.value = 0
      }).finally(() => {
        router.replace({
          query: queryParams,
        }).catch(() => {})
      })
    }
    searchSentSMSList()

    watch(currentPage, () => {
      searchSentSMSList(currentPage.value)
    })

    return {
      // ref
      startAt,
      endAt,
      typeOptions,
      resultOptions,
      selectedMsgType,
      result,
      phoneNumber,
      failReason,

      // table ref
      searchSentSMSList,
      searchResult,
      resultTotalCount,
      tableColumnNames,
      currentPage,
      size,
      showFullText,
      fullText,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.table-content__text {
  overflow: hidden;
  white-space: nowrap;
  width: 600px;
  text-overflow: ellipsis;
}
</style>
